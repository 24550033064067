import { HttpClient } from '@angular/common/http';
import {Component, Inject,OnInit} from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-script-popup',
  templateUrl: './script-popup.component.html',
  styleUrls: ['./script-popup.component.css']
})
export class ScriptPopupComponent implements OnInit {
  response: any
  constructor(  private httpClient:HttpClient , @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    this.httpClient.get(this.data.url).subscribe(resp=>{
this.response= resp
    })
  }
  

}
