import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SearchService {

  userId: string
  constructor(private http: HttpClient) {
    this.userId = sessionStorage.getItem("userId") || '';
  }

  getApplications() {
    return this.http.get(environment.apiUrl + '/application/getAllApplications');
  }


  getActionTypes(appId: any) {
    return this.http.get(environment.apiUrl + '/application/getAppActionsbyAppId/' + appId);
  }

  getAllPresentations() {
    return this.http.get(environment.apiUrl + '/application/getAllPresentations');
  }

  getPricingMethod() {
    return this.http.get(environment.apiUrl + '/search/getPricingMethod');
  }
  getCustTypeCodes() {
    return this.http.get(environment.apiUrl + '/search/getCustTypeCodes');
  }

  getCustTypeCodesByID(fieldCode: any) {
    return this.http.get(environment.apiUrl + '/search/getCustTypeCodesByID/'+fieldCode);
  }

  getActiveFlag() {
    return this.http.get(environment.apiUrl + '/search/getActiveFlag');
  }

  search(reqBody: any) {
    return this.http.post(environment.apiUrl + '/search/', reqBody);
  }

 
  getPriorityCodeDesc() {
    return this.http.get(environment.apiUrl + '/search/getPriorityCodeDesc');
  }

  saveCampaign(reqBody: any){
    const url = `${environment.apiUrl}/campaigns/saveCampaign/${this.userId}`
    return this.http.post(url, reqBody);
  }
  deleteCampaign(id: any){
    return this.http.get(environment.apiUrl + '/campaigns/saveCampaign/'+id);
  }

}
