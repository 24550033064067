<ul id="myUL">
  <li >
    <span class="caret"  >{{applicationName}} (Application)</span>
    <ul class="nested">
      <span class="caret">{{actionTypeName}} (Type of Action)</span>
      <ul class="nested">
        <li *ngFor="let camp of searchResult">
          <div class="caret row">
              <div class="col-md-4">{{camp.name}} (Campaign)</div>
              <div class="col-md-1" (click)="editCampaign(camp)">[Edit]</div>
              <div class="col-md-1" ><a (click)="editTriggers(camp)">[Triggers]</a></div>
              <div class="col-md-1"><a href="#">[Propositions]</a></div>
              
          </div>
          <ul>
            <li>EFF DATE: {{trasnformDates(camp.effectiveDate)}} END DATE: {{trasnformDates(camp.endDate)}}  </li>
          </ul>
          <ul class="nested" >
            <div class="proposition">
            <li  *ngFor="let proposition of camp.propositions; let i= index ">
           
                    <div class="row ">
                      <table  class="col-md-5 one">
                <div>
                  <div class="box" *ngIf="i==0">Propositions</div>
                  <span class="caret">{{proposition.name}} (Proposition)</span>
                  <ul class="nested">
                    <li>EFF DATE : {{trasnformDates(proposition.effectiveDate)}} END DATE :
                      {{trasnformDates(proposition.endDate)}} </li>
                    <li> Priority Code : {{getpriorityCodeDescFieldDescription(proposition.priorityCode)}} Presentation
                      Id : {{getpresentationIdFieldDescription(proposition.presentationId)}} </li>
                    <li *ngFor="let propositionAttributeGroup of proposition.propositionAttributeGroups"><span
                        class="caret">{{propositionAttributeGroup.name}}</span>
                      <ul class="nested">
                        <li>EFF DATE : {{trasnformDates(propositionAttributeGroup.effectiveDate)}} END DATE :
                          {{trasnformDates(propositionAttributeGroup.endDate)}} <a href="#"
                            (click)="openPopUp(propositionAttributeGroup.scriptURL)">[Preview Script]</a></li>
                        <li>Type Code : Coupon Pricing Method Code :
                          {{getpricingMethodCodeFieldDescription(propositionAttributeGroup.priceMethodCode)}}</li>
                        <li *ngFor="let propositionAttribute of propositionAttributeGroup.propositionAttributes">
                          <span class="caret">{{propositionAttribute.name}}</span>
                          <ul class="nested">
                            <li>Value: {{getPropositionAttribute(propositionAttribute)}} Active:
                              {{fieldMapper[propositionAttribute.activeInd]}}
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>    
              </table>   
              <table class="col-md-7 two">  
                <div >
                  <div class="box" *ngIf="i==0">Triggers</div>
                  <span class="caret"> {{ proposition.trigger.name }} (Trigger)</span>
                  <ul class="nested">
                    <li *ngFor="let triggerAttributeGroup of proposition.trigger.triggerAttributeGroups">
                      <span class="caret">{{triggerAttributeGroup.name}} (Attribute Group )</span>
                      <ul class="nested">
                        <li>EFF DATE : {{trasnformDates(triggerAttributeGroup.effectiveDate)}} END DATE :
                          {{trasnformDates(triggerAttributeGroup.endDate)}}</li>
                        <li *ngFor="let triggerAttribute of triggerAttributeGroup.triggerAttributes">
                          <span class="carret">{{triggerAttribute.name}} (Attribute)</span>
                          <ul>
                            <li>Type : {{AVTCfieldMapper[triggerAttribute.valueTypeCode]}} Condition :
                              {{fieldMapper[triggerAttribute.operatorCode]}} Value : {{triggerAttribute.valueText}}
                              Required : {{RANFfieldMapper[triggerAttribute.requiredFlag]}} Active :
                              {{fieldMapper[triggerAttribute.activeInd]}}
                              Customer Dependent : {{fieldMapper[triggerAttribute.custDependentFlag]}}</li>
                          </ul>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
                </table>
       
          
            </div>
            </li>
          </div>
          </ul>
        </li>
      </ul>
    </ul>

  </li>

</ul>