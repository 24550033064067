<app-header></app-header>
<mat-tab-group [(selectedIndex)]="tabIndex" (SelectedTabChange)="OnTabChange($event)">
    <mat-tab label="Search" style="overflow: unset !important;">
        <app-search></app-search>
    </mat-tab>
    <mat-tab label="Campaigns">
        <ng-template matTabContent>
            <app-campaigns></app-campaigns>
        </ng-template>
    </mat-tab>
    <mat-tab label="Triggers">
        <app-triggers></app-triggers>
    </mat-tab>
    <mat-tab label="Propositions">
        <app-propositions></app-propositions>
    </mat-tab>
    <mat-tab label="Preview Campaign">
        <app-preview-campaign></app-preview-campaign>
    </mat-tab>
    <mat-tab label="Testing">
        <app-testing></app-testing>
    </mat-tab>
    <mat-tab label="Migration">
        <app-migration></app-migration>
    </mat-tab>

</mat-tab-group>