<div class="container-fluid">
  <form [formGroup]="searchForm" (ngSubmit)="search(searchForm.value)">
    <div class="row">
      <span></span>
      <table class="block col-md-4">
        <tr>
          <td><label class="col-md-4" ><span class="required col-md-4 text-center"> * </span>Application Name :</label></td>
       
          <td><select class="col-md-8" (change)="onApplicationChange($event)" formControlName="applicationId" required>
              <option [ngValue]="null" value="-1"selected="selected">---Select---</option>
              <option *ngFor="let app of applications" [value]="app.key">{{app.value}}</option>
            </select></td>
        </tr>
        <tr>
          <td><label class="col-md-4"><span class="required col-md-5"> * </span> Type of Action :</label></td>
          <td><select class="col-md-8" (change)="onActionTypeChange($event)"  formControlName="actionType" [(ngModel)]="SelectedActionType" required>
              <option value="-1">---Select---</option>
              <option *ngFor="let app of actionTypes" [value]="app.key">{{app.value}}</option>
            </select></td>
        </tr>
          <tr>
          <td><label class="col-md-4">Campaign :</label> </td>
          <td>
            <div class="row">
            <div>
              <select class="selectbox" formControlName="campaignPattern" [(ngModel)]="selectedcampaignValue">
                <option *ngFor="let val of textContains" 
                [value]="val.key">{{val.value}}</option>
              </select>
             <input  class="txtbox" type="text" formControlName="campaignValue"></div>
            </div>
             
         

  
          </td>

        </tr>
        <tr>
          <td  class="col-md-4"><label>Date :</label> </td>
          <td  class="col-md-8"><input type="date" formControlName="campaignEffDate">
        </tr>
        <tr>
          <td  class="col-md-4"><label>End Date :</label></td>
          <td  class="col-md-8"><input type="date" formControlName="campaignEndDate">

        </tr>
        <tr>
          <td  class="col-md-4"><label>Presentation :</label></td>
          <td><select class="col-md-8" formControlName="presentation">
              <option value="-1">---Select---</option>
              <option *ngFor="let val of presentation" [value]="val.key">{{val.value}}</option>
            </select></td>
        </tr>
        <tr>
          <td  class="col-md-4"><label>Code :</label> </td>
          <td><select class="col-md-8" formControlName="typeCode">
              <option value="-1">---Select---</option>
              <option *ngFor="let val of typeCode" [value]="val.fieldCode">{{val.fieldDesc}}</option>
            </select></td>
        </tr>
        <tr>
          <td class="col-md-4"><label>Pricing Method :</label></td>
          <td><select class="col-md-8" formControlName="pricingMethod">
              <option value="-1">---Select---</option>
              <option *ngFor="let val of pricingMethod" [value]="val.fieldCode">{{val.fieldDesc}}</option>
            </select></td>
        </tr>
        <tr>
          <td  class="col-md-4"><label>Active:</label></td>
          <td><select class="col-md-8" formControlName="active">
              <option value="-1">---Select---</option>
              <option *ngFor="let val of active" [value]="val.fieldCode">{{val.fieldDesc}}</option>
            </select></td>

        </tr>
      </table>
      <table class="block col-md-4">
        <tr>
          <td  class="col-md-4"><label>Trigger :</label></td>
          <td>
            <div class="row">
              <div><select  class="selectbox" formControlName="triggerPattern">
                  <option *ngFor="let val of textContains"  [value]="val.key">{{val.value}}</option>
                </select>
              <input class="textinput" type="text" formControlName="triggerValue"></div>
            </div>
          </td>
        </tr>
        <tr>
          <td class="col-md-4"> <label>Attribute Group :</label> </td>
          <td>
            <div class="row">
              <div ><select  class="selectbox col-md-8" formControlName="triggerAttributeGroupPattern">
                  <option *ngFor="let val of textContains"  [value]="val.key">{{val.value}}</option>
                </select><input class="textinput" type="text" formControlName="triggerAttributeGroupValue">
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td class="col-md-4"> <label>Effective Date :</label></td>
          <td class="col-md-8"><input type="date" formControlName="triggerEffDate">
          </td>
        </tr>
        <tr>
          <td class="col-md-4"><label>End Date:</label></td>
          <td class="col-md-8"><input type="date" formControlName="triggerEndDate">
          </td>
        </tr>

        <tr>
          <td class="col-md-4"> <label>Attribute 1 :</label></td>
          <td>
            <div class="row">
              <div>
                <select class="selectbox" formControlName="triggerAttr1Key">
                  <option value="-1">---Select----</option>
                  <option></option>
                </select>
                <input type="text" formControlName="triggerAttr1Value" class="textinput">
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td class="col-md-4"><label>Attribute 2 :</label></td>
          <td>
            <div class="row">
              <div>
                <select  class="selectbox col-md-8" formControlName="triggerAttr2Key">
                  <option value="-1">---Select---</option>
                  <option></option>
                </select>
                <input type="text" formControlName="triggerAttr2Value" class="textinput">
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td class="col-md-4"><label>Attribute 3 :</label></td>
          <td>
            <div class="row">
              <div>
                <select class="selectbox col-md-8" formControlName="triggerAttr3Key">
                  <option value="-1">---Select---</option>
                  <option></option>
                </select>
                <input type="text" formControlName="triggerAttr3Value">
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td class="col-md-4"><label>Attribute 4 :</label></td>
          <td>
            <div class="row">
              <div>
                <select  class="selectbox col-md-8" formControlName="triggerAttr4Key">
                  <option value="-1">---Select---</option>
                  <option></option>
                </select>
                <input type="text" formControlName="triggerAttr4Value">
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td class="col-md-4"><label>Attribute 5:</label></td>
          <td>
            <div class="row">
              <div>
                <select  class="selectbox col-md-8" formControlName="triggerAttr5Key">
                  <option value="-1">---Select---</option>
                  <option></option>
                </select>
                <input type="text" formControlName="triggerAttr5Value">
              </div>
            </div>
          </td>
        </tr>
      </table>
      <table class="block col-md-4">
        <tr>
          <td class="col-md-4"><label>Proposition : </label></td>
          <td>
            <div class="row">
              <div>
                <select class="selectbox"  formControlName="propositionPattern">
                  <option *ngFor="let val of textContains"  [value]="val.key">{{val.value}}</option>
                </select>
                <input  type="text" formControlName="propositionKey">
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td class="col-md-4"><label> Proposition Attribute Group :</label></td>
          <td>
            <div class="row">
              <div>
                <select class="selectbox"  formControlName="propositionAttrGroupPattern">
                  <option *ngFor="let val of textContains" [value]="val.key">{{val.value}}</option>
                </select>
                <input  type="text" formControlName="propositionAttrGroupValue">
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td class="col-md-4"> <label>Effective Date :</label></td>
          <td  class="col-md-8"><input type="date" formControlName="propositionEffDate">
          </td>
        </tr>
        <tr>
          <td class="col-md-4"><label>End Date :</label></td>
          <td class="col-md-8"><input type="date" formControlName="propositionEndDate">
          </td>
        </tr>
        <tr>
          <td class="col-md-4"><label>Attribute 1 :</label></td>
          <td>
            <div class="row">
              <div>
                <select  class="selectbox" formControlName="propositionAttr1Key">
                  <option *ngFor="let val of textContains" [value]="val">{{val}}</option>
                </select>
                <input type="text" formControlName="propositionAttr1Value">
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td class="col-md-4"><label>Attribute 2 :</label></td>
          <td>
            <div class="row">
              <div>
                <select class="selectbox" formControlName="propositionAttr2Key">
                  <option *ngFor="let val of textContains" [value]="val">{{val}}</option>
                </select>
                <input type="text" formControlName="propositionAttr2Value">
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td class="col-md-4"><label>Attribute 3 :</label></td>
          <td>
            <div class="row">
              <div>
                <select class="selectbox" formControlName="propositionAttr3Key">
                  <option *ngFor="let val of textContains" [value]="val">{{val}}</option>
                </select>
                <input type="text" formControlName="propositionAttr3Value">
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td class="col-md-4"><label>Attribute 4 :</label></td>
          <td>
            <div class="row">
              <div >
                <select class="selectbox" formControlName="propositionAttr4Key">
                  <option *ngFor="let val of textContains" [value]="val">{{val}}</option>
                </select>
                <input type="text" formControlName="propositionAttr4Value">
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td class="col-md-4"><label>Attribute 5 :</label></td>
          <td>
            <div class="row">
              <div >
                <select class="selectbox" formControlName="propositionAttr5Key">
                  <option *ngFor="let val of textContains" [value]="val">{{val}}</option>
                </select>
            
                <input type="text" formControlName="propositionAttr5Value">
              </div>
            </div>
          </td>
        </tr>
      </table>
 

    </div>
    <div class="row margin-top">
      <div class="col-md-4"></div>
      <div class="col-md-2">
        <button>Search</button>
      </div>
      <div class="col-md-2">
        <button (click)="reset()" type="button">Cancel</button>
      </div>
      <div class="col-md4"></div>
    </div>
  </form>
  <div class="box">Campaign Information</div>
  <div class="spinner">
    <mat-spinner *ngIf="searchResult && showSpinner"></mat-spinner>
  </div>


  <app-search-result *ngIf="searchResult && searchResult.length > 0" [searchResult]="searchResult" (TriggerResponse)="HandleTriggerResponse($event)">
  </app-search-result>


</div>