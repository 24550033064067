import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LoginService } from './login.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  hide: boolean
  loginForm: FormGroup
  loginErrorMessage: string;
  submitted = false;
  constructor(private loginService: LoginService,
    private router: Router,
    private formBuilder: FormBuilder) {
    this.hide = true;
    this.loginErrorMessage = '';
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
    });
  }


  ngOnInit(): void {

  }

  get f() { return this.loginForm.controls; }

  onSubmit() {
    this.submitted = true;
    console.log(this.loginForm.value)
    if (this.loginForm.valid) {
      this.loginService.login(this.loginForm.get('username')?.value, this.loginForm.get('password')?.value).subscribe((resp: any) => {
        console.log(resp)
        if (resp.token) {
          sessionStorage.setItem('loggedInUser', JSON.stringify(resp))
          sessionStorage.setItem("userId", this.loginForm.get('username')?.value)

          this.router.navigate(['dashboard'])
        } else {
          this.router.navigate(['login'])
        }

      }, err => {
        this.loginErrorMessage = 'Invalid Credentials'
        sessionStorage.setItem('loggedInUser', '')
        this.router.navigate(['login'])
      })
    }

  }

}
