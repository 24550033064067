<div class="row">
    <div class="col-md-3"></div>
    <div class="col-md-6">
        <mat-card class="login-card">
            <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                <mat-card-title> Home Services - CAMPAIGN MANAGER </mat-card-title>
                <mat-card-header>
                    <mat-card-title>Login</mat-card-title>
                </mat-card-header>
                <mat-card-content>

                    <div class="row">
                        <span class="error"> {{loginErrorMessage}}</span>
                        <mat-form-field appearance="fill" class="col-md-6">
                            <mat-label>Username</mat-label>
                            <input matInput type="text" formControlName="username" class="example-right-align"
                                placeholder="Username">
                        </mat-form-field>

                        <mat-form-field appearance="fill" class="col-md-6">
                            <mat-label>Password</mat-label>
                            <input matInput formControlName="password" [type]="hide ? 'password' : 'text'"
                                placeholder="Enter Password">

                        </mat-form-field>
                    </div>



                </mat-card-content>
                <mat-card-actions>
                    <button mat-raised-button color="primary">Login</button>
                </mat-card-actions>
            </form>
        </mat-card>
    </div>
    <div class="col-md-3"></div>
</div>