import { Component, OnInit } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { EnvAsyncService } from '../util/env-async.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  loggedInUser: any
  tabIndex: number = 0;
  constructor(private envAsyncService: EnvAsyncService) { }

  ngOnInit(): void {
    const str: any = sessionStorage.getItem('loggedInUser');
    this.loggedInUser = JSON.parse(str);
    this.envAsyncService.getCurrentTab().subscribe(no =>{
      this.tabIndex = no
    })
  }
  OnTabChange(event:any){

  }

}
