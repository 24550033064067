import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SearchService } from '../search/search.service';


@Component({
  selector: 'app-campaigns',
  templateUrl: './campaigns.component.html',
  styleUrls: ['./campaigns.component.css']
})
export class CampaignsComponent implements OnInit, OnDestroy {
  applications: any[] = [];
  actionTypes: any[] = [];
  campaignName: any[] = [];
  typeCode: any[] = [];
  effpicker: any = new Date();
  endpicker: any = new Date()
  purgeFlag: any[] = ['yes', 'no'];
  applicationName: string = ''
  actionTypeName: string = ''
  campaignEditForm: FormGroup;
  showSpinner: boolean = false
  editCamp: any;
  search: any;
  effDate: any;
  endDate: any;
  campKey: any;
  SelectedApplicationId : any;
  constructor(private searchService: SearchService, private formBuilder: FormBuilder,
    private _snackBar: MatSnackBar) {
    this.campaignEditForm = formBuilder.group({
      applicationId: [null],
      actionType: [null],
      campaignName: [null],
      campaignEffDate: [null],
      campaignEndDate: [null],
      typeCode: [null],
      purgeFlag: [null],


    });
  }
  ngOnDestroy(): void {
    sessionStorage.setItem("campName", "")
  }
  onApplicationChange(event: any) {
    this.applicationName = this.applications.filter(a => a.key == event.target.value)[0].value

    this.searchService.getActionTypes(event.target.value).subscribe((resp: any) => {
      this.actionTypes = resp;
    })

  }
  onActionTypeChange(event: any) {
    this.actionTypeName = this.actionTypes.filter(a => a.key == event.target.value)[0].value
  }

  formatDate(date: string) {
    const arr = date.split('T')[0].split('-');
    return arr[1] + '-' + arr[2] + '-' + arr[0]
  }
  
  ngOnInit(): void {
    this.searchService.getApplications().subscribe((resp: any) => {
      this.applications = resp
      this.searchService.getCustTypeCodes().subscribe((resp: any) => {
        this.typeCode = resp
        this.editCamp = JSON.parse(sessionStorage.getItem("campName") || "");
        this.search = sessionStorage.getItem("search");
        if (this.editCamp) {
          this.campKey = this.editCamp.campaignActionDO.application.idNo;
          const target = {
            target: {
              value: this.campKey
            }
          }
          this.onApplicationChange(target)
          this.campaignEditForm.controls['applicationId'].setValue(this.campKey, { onlySelf: true });
          this.campaignEditForm.controls['actionType'].setValue(this.editCamp.campaignActionDO.actionType.idNo, { onlySelf: true });
          this.typeCode = JSON.parse(sessionStorage.getItem('typeCode') || '')
          const fieldCode = this.typeCode.filter((x: any) => x.fieldCode == this.editCamp.typeCode)[0]
          this.campaignEditForm.controls['typeCode'].setValue(fieldCode.fieldCode, { onlySelf: true });

          this.campaignEditForm.get('campaignName')?.patchValue(this.editCamp.name);
          this.effDate = new Date(this.editCamp.effectiveDate)
          this.endDate = new Date(this.editCamp.endDate)
          this.campaignEditForm.controls['purgeFlag']?.setValue(this.editCamp.purgeFlag.toLowerCase() == 'n' ? 'no' : 'yes', { onlySelf: true });
        }
      })
    })



  }

  reset() {
    this.campaignEditForm.reset();
  }

  save() {
    this.showSpinner = true
    this.searchService.saveCampaign(this.campaignEditForm.value).subscribe(resp => {
      this.showSpinner = false;
      this._snackBar.open("success", "", {
        duration: 3000,
      })
    });
  }

  delete(){

    alert(this.SelectedApplicationId);
    // this.searchService.deleteCampaign(this.SelectedApplicationId).subscribe(resp=>{

    // })

  }

}
