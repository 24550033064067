import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EnvAsyncService {

  currentEnv = new Subject<string>()
  currentTab = new Subject<number>()
  constructor() { }

  setCurrentTab(no: number) {
    this.currentTab.next(no)
  }

  getCurrentTab() {
    return this.currentTab
  }
  getCurrentEnv() {
    return this.currentEnv;
  }

  setCurrentEnv(env: string) {
    this.currentEnv.next(env)
  }
}
