import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CampaignsComponent } from './campaigns/campaigns.component';
import { TriggersComponent } from './triggers/triggers.component';
import { PropositionsComponent } from './propositions/propositions.component';
import { PreviewCampaignComponent } from './preview-campaign/preview-campaign.component';
import { TestingComponent } from './testing/testing.component';
import { MigrationComponent } from './migration/migration.component';
import { SearchComponent } from './search/search.component';

import {MatCardModule} from '@angular/material/card';
import {MatInputModule} from '@angular/material/input';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { DashboardComponent } from './dashboard/dashboard.component';
import {MatTabsModule} from '@angular/material/tabs';
import { HeaderComponent } from './header/header.component';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatTreeModule} from '@angular/material/tree';
import { SearchResultComponent } from './search-result/search-result.component';
import { MatNativeDateModule } from '@angular/material/core';
import { ScriptPopupComponent } from './script-popup/script-popup.component';
import {MatDialogModule} from '@angular/material/dialog';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import { AddtriggerComponent } from './triggers/addtrigger/addtrigger.component';
import { AngularDualListBoxModule } from 'angular-dual-listbox';


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    CampaignsComponent,
    TriggersComponent,
    PropositionsComponent,
    PreviewCampaignComponent,
    TestingComponent,
    MigrationComponent,
    SearchComponent,
    DashboardComponent,
    HeaderComponent,
    SearchResultComponent,
    ScriptPopupComponent,
    AddtriggerComponent
  ],
  imports: [
    MatNativeDateModule,
    BrowserModule,
    AngularDualListBoxModule,
    MatTabsModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatCardModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    MatTreeModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    MatSnackBarModule
  ],
  providers: [],
  bootstrap: [AppComponent],
  entryComponents:[ScriptPopupComponent]
})
export class AppModule { }
