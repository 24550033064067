import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EnvAsyncService } from '../util/env-async.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  

  loggedInUser: any
  selectedEnv: any;
  constructor(private router: Router, private envAsyncService: EnvAsyncService) { }

  ngOnInit(): void {
    const str: any = sessionStorage.getItem('loggedInUser');
    this.loggedInUser = JSON.parse(str);
    if(!this.loggedInUser){
      this.router.navigate(['login'])
    }
    this.envAsyncService.getCurrentEnv().subscribe(resp =>{
      this.selectedEnv = resp
    })
  }

  logout(){
    sessionStorage.removeItem('loggedInUser');
  }

}
