import {FlatTreeControl} from '@angular/cdk/tree';
import {Component, Input} from '@angular/core';
import {MatTreeFlatDataSource, MatTreeFlattener} from '@angular/material/tree';

export interface FoodNode {
  name: string;
  effectiveDate:any;
  endDate:any;
  triggerAttributeGroups?: any;
}


const TREE_DATA: FoodNode[] = [
  {
    "name": "CM-DISCLOSE-DISC",
    "effectiveDate": "2012-01-26T06:00:00.000+00:00",
    "endDate": "2012-10-31T05:00:00.000+00:00",
    "triggerAttributeGroups": [
      {
        "idNo": 19868,
        "name": "CM-DISCLOSE-DISC",
        "lastUpdateIdCode": "kearl   ",
        "lastUpdateTimestamp": "2012-11-03T00:03:39.000+00:00",
        "activeInd": null,
        "effectiveDate": "2012-01-26T06:00:00.000+00:00",
        "endDate": "2012-10-31T05:00:00.000+00:00",
        "createIdCode": null,
        "createTimestamp": null,
        "currentActiveState": 0,
        "triggerAttributeGroups": [
          {
            "idNo": 92731,
            "name": "CASERESOLNAME",
            "lastUpdateIdCode": "kearl   ",
            "lastUpdateTimestamp": "2012-11-03T00:03:39.000+00:00",
            "activeInd": "Y",
            "used": false,
            "valueTypeCode": "T",
            "nameTypeCode": null,
            "validValueFlag": "N",
            "attributeValues": null,
            "custDependentFlag": "N",
            "operatorCode": "EQ",
            "requiredFlag": "Y",
            "valueNumber": null,
            "valueDate": null,
            "valueText": "PROD-DISC10P",
            "desc": "CASERESOLNAME (Equals) PROD-DISC10P",
            "attributeId": 256,
            "attributeGroupId": 19868
          },
          {
            "idNo": 86455,
            "name": "CASERESOLTYPE",
            "lastUpdateIdCode": "kearl   ",
            "lastUpdateTimestamp": "2012-11-03T00:03:39.000+00:00",
            "activeInd": "Y",
            "used": false,
            "valueTypeCode": "T",
            "nameTypeCode": null,
            "validValueFlag": "N",
            "attributeValues": null,
            "custDependentFlag": "N",
            "operatorCode": "EQ",
            "requiredFlag": "Y",
            "valueNumber": null,
            "valueDate": null,
            "valueText": "DISCOUNT",
            "desc": "CASERESOLTYPE (Equals) DISCOUNT",
            "attributeId": 225,
            "attributeGroupId": 19868
          },
          {
            "idNo": 92732,
            "name": "CONCESSIONSTATUS",
            "lastUpdateIdCode": "kearl   ",
            "lastUpdateTimestamp": "2012-11-03T00:03:39.000+00:00",
            "activeInd": "Y",
            "used": false,
            "valueTypeCode": "T",
            "nameTypeCode": null,
            "validValueFlag": "N",
            "attributeValues": null,
            "custDependentFlag": "N",
            "operatorCode": "EQ",
            "requiredFlag": "Y",
            "valueNumber": null,
            "valueDate": null,
            "valueText": "Completed",
            "desc": "CONCESSIONSTATUS (Equals) Completed",
            "attributeId": 229,
            "attributeGroupId": 19868
          }
        ],
        "triggerId": 2455
      }
    ]
  }
];
interface ExampleFlatNode {
  expandable: boolean;
  name: string;
  level: number;
}
@Component({
  selector: 'app-triggers',
  templateUrl: './triggers.component.html',
  styleUrls: ['./triggers.component.css']
})
export class TriggersComponent {
  TREE_DATA: FoodNode[];
  nodelevel:any;
  isShow:boolean=false;
  isShowAddTriggerPage:boolean=false;
  Counter=0;
  @Input()
  searchResult: any[] = [];
private _transformer = (node: FoodNode, level: number) => {
  var name=''
  if(level==0){
     name=node.name+" "+'(Trigger)'
     this.nodelevel=level;
     this.EffectiveDate=this.trasnformDates(node.effectiveDate);
     this.EndDate=this.trasnformDates(node.endDate);
  }
  if(level==1){
    this.nodelevel=level;
    name=node.name +" "+'(Attribute Group)';
 }
 if(level==2){
  this.nodelevel=level;
  if(this.Counter==0){
    this.isShow=true;
  }
  else{
    this.isShow=true;
  }
  this.Counter++;
  name=node.name +" "+'(Attribute)';
}

  return {
    expandable: !!node.triggerAttributeGroups && node.triggerAttributeGroups.length > 0,
    name: name,
    level: level,
  };
};

EffectiveDate:any;
EndDate:any;

treeControl = new FlatTreeControl<ExampleFlatNode>(
  node => node.level,
  node => node.expandable,
);

treeFlattener = new MatTreeFlattener(
  this._transformer,
  node => node.level,
  node => node.expandable,
  node => node.triggerAttributeGroups,
);

dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

constructor() {

  this.dataSource.data = TREE_DATA;
  
}

hasChild = (_: number, node: ExampleFlatNode) => node.expandable;

trasnformDates(date: any) {
  let dateArr
  if (date) {
    dateArr = date.split('T')[0].split('-')
  }
  return dateArr[1] + '/' + dateArr[2] + '/' + dateArr[0];
}
getTriggerData(){
  console.log(this.searchResult);
}
AddTrigger(){
  this.isShowAddTriggerPage=true;
}
addTriggerResponse(event:any){
  this.isShowAddTriggerPage=event;
}
}


