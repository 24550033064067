import { AfterViewInit, Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { delay } from 'rxjs';
import { SearchService } from './search.service';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css']
})
export class SearchComponent implements OnInit {
  @Output() public searchResponse= new EventEmitter<any>();
  applications: any[] = [];
  actionTypes: any[] = [];
  presentation: any[] = [];
  searchResult: any[] = [];
  typeCode: any[] = [];
  pricingMethod: any[] = [];
  active: any[] = [];
  textContains = [{ key: 1, value: "Starts With" }, { key: 2, value: " Contians" }, { key: 3, value: "Ends With" }]
  ngSelect=this.textContains[0].key;
  searchForm: FormGroup;
  effpicker: any = new Date();
  endpicker: any = new Date()
  applicationName: string = ''
  actionTypeName: string = ''
  SelectedActionType:any;
  selectedcampaignValue:any;
  public isSearchBtnDsbl : boolean =false;
  showSpinner:boolean =false

  //@ViewChild('caret') someInput!: ElementRef;
  constructor(private searchService: SearchService, private formBuilder: FormBuilder) {
    this.searchForm = formBuilder.group({
      applicationId: [null],
      actionType: [null],
      campaignPattern: [null],
      campaignValue: [null],
      campaignEffDate: [null],
      campaignEndDate: [null],
      presentation: [null],
      typeCode: [null],
      pricingMethod: [null],
      active: [null],

      triggerPattern: [null],
      triggerValue: [null],
      triggerAttributeGroupPattern: [null],
      triggerAttributeGroupValue: [null],
      triggerEffDate: [null],
      triggerEndDate: [null],

      triggerAttr1Key: [null],
      triggerAttr2Key: [null],
      triggerAttr3Key: [null],
      triggerAttr4Key: [null],
      triggerAttr5Key: [null],

      triggerAttr1Value: [null],
      triggerAttr2Value: [null],
      triggerAttr3Value: [null],
      triggerAttr4Value: [null],
      triggerAttr5Value: [null],


      propositionPattern: [null],
      propositionValue: [null],
      propositionAttrGroupPattern: [null],
      propositionAttrGroupValue: [null],
      propositionEffDate: [null],
      propositionEndDate: [null],

      propositionAttr1Key: [null],
      propositionAttr2Key: [null],
      propositionAttr3Key: [null],
      propositionAttr4Key: [null],
      propositionAttr5Key: [null],

      propositionAttr1Value: [null],
      propositionAttr2Value: [null],
      propositionAttr3Value: [null],
      propositionAttr4Value: [null],
      propositionAttr5Value: [null]
    });
  }
  
  
  ngOnInit(): void {
    this.searchService.getApplications().subscribe((resp: any) => {
      this.applications = resp
    })
    this.searchService.getAllPresentations().subscribe((resp: any) => {
      this.presentation = resp
    })
    this.searchService.getCustTypeCodes().subscribe((resp: any) => {
      sessionStorage.setItem('typeCode', JSON.stringify(resp))
      this.typeCode = resp
    })
    this.searchService.getPricingMethod().subscribe((resp: any) => {
      this.pricingMethod = resp
    })
    this.searchService.getActiveFlag().subscribe((resp: any) => {
      this.active = resp
    })
    this.SelectedActionType=-1;
    this.selectedcampaignValue = this.textContains[0].key;


  };



  onApplicationChange(event: any) {
    this.applicationName = this.applications.filter(a=> a.key == event.target.value)[0].value
    sessionStorage.setItem('search-application-name', this.applicationName)
    this.searchService.getActionTypes(event.target.value).subscribe((resp: any) => {
      this.actionTypes = resp;
    })
    this.SelectedActionType=-1;
  
  }

  
  onActionTypeChange(event: any){
    this.actionTypeName = this.actionTypes.filter(a=> a.key == event.target.value)[0].value
    // sessionStorage.setItem('search-action-type-name', this.actionTypeName)
  }
  search(search: any) {
    if((this.searchForm.value.applicationId==-1 ||  this.searchForm.value.applicationId==null)
        && (this.searchForm.value.actionType==-1 || this.searchForm.value.actionType==null))
    alert("Application Name is required. \n Type of Action is required."+ this.searchForm.value.applicationId);

    else if(this.searchForm.value.applicationId==-1 ||  this.searchForm.value.applicationId==null )
    alert("Application Name is required."+ this.searchForm.value.applicationId);

    else if(this.searchForm.value.actionType==-1 || this.searchForm.value.actionType==null)
    alert("Type of Action is required.")
    else{
    console.log(this.searchForm.value)
    this.showSpinner = true
    this.searchResult = [];
    this.searchService.search(this.searchForm.value).subscribe((resp: any) => {
      console.log(resp);
      this.searchResult = resp;
      this.showSpinner = false
    })
    }
  }
  
  reset(){
    this.searchForm.reset();
    this.searchResult=[];
    this.showSpinner=false;
  }
  HandleTriggerResponse(res:any){
    this.searchResponse.emit(res);
  }
  
}
