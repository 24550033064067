import { AfterContentChecked, AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ScriptPopupComponent } from '../script-popup/script-popup.component';
import { SearchService } from '../search/search.service';
import { EnvAsyncService } from '../util/env-async.service';

@Component({
  selector: 'app-search-result',
  templateUrl: './search-result.component.html',
  styleUrls: ['./search-result.component.css']
})
export class SearchResultComponent implements OnInit, AfterViewInit, AfterContentChecked {

  @Input()
  searchResult: any[] = [];
  @Output() public TriggerResponse = new EventEmitter<any>()

  public applicationName: string = '';

  public actionTypeName: string = '';

  fieldMapper: any = {
    Y: 'YES',
    N: 'NO',
    EQ: 'EQUALS',
    NE: 'Not-Equal',
    ge: 'Greater-Than-Or-Equal-To',
    gt: 'Greater-Than',
    le: 'Less-Than-Or-Equal-To',
    lt: 'Less-Than',
    ne: 'Not-Equal-To'
  }

  AVTCfieldMapper: any = {
    N: 'Number',
    D: 'Date',
    T: 'Text',
  }

  RANFfieldMapper: any = {
    Y: 'Required',
    N: 'Not-Required',
  }

  constructor(private searchService: SearchService,
    public dialog: MatDialog,
    private envAsyncService: EnvAsyncService) { }


  presentationId: any;
  pricingMethodCode: any;
  priorityCode: any;

  ngOnInit(): void {

    this.searchService.getPriorityCodeDesc().subscribe((resp: any) => {
      this.priorityCode = resp
    })

    this.searchService.getAllPresentations().subscribe((resp: any) => {
      this.presentationId = resp
    })

    this.searchService.getPricingMethod().subscribe((resp: any) => {
      this.pricingMethodCode = resp
    })
    

  }
  ngAfterViewInit(): void {
    var toggler = document.getElementsByClassName("caret");
    var i;

    for (i = 0; i < toggler.length; i++) {
      const obj: any = toggler[i];
      obj.addEventListener("click", () => {
        obj.parentElement.querySelector(".nested").classList.toggle("active");
        obj.classList.toggle("caret-down");
      });
    }

  }

  ngAfterContentChecked() : void {
    this.applicationName=this.searchResult[0].campaignActionDO.application.name;
    this.actionTypeName=this.searchResult[0].campaignActionDO.actionType.name;
  }

  getpriorityCodeDescFieldDescription(code: any) {
    const pc = this.priorityCode.filter((p: any) => p.fieldCode == code)
    if (pc && pc.length > 0) {
      return pc[0].fieldDesc
    } else {
      return "";
    }
  }

  getpresentationIdFieldDescription(code: any) {
    const pc = this.presentationId.filter((p: any) => p.key == code)
    if (pc && pc.length > 0) {
      return pc[0].value
    } else {
      return "";
    }
  }

  getpricingMethodCodeFieldDescription(code: any) {
    const pc = this.pricingMethodCode.filter((p: any) => p.fieldCode == code)
    if (pc && pc.length > 0) {
      return pc[0].fieldDesc
    } else {
      return "";
    }
  }

  getPropositionAttribute(propositionAttribute: any) {
    if (propositionAttribute.name == "PORT") {
      return propositionAttribute.valueNumber
    } else {
      return propositionAttribute.valueText
    }
  }

  trasnformDates(date: any) {
    let dateArr
    if (date) {
      dateArr = date.split('T')[0].split('-')
    }
    return dateArr[1] + '/' + dateArr[2] + '/' + dateArr[0];
  }

  openPopUp(url: any) {
    const dialogRef = this.dialog.open(ScriptPopupComponent, {
      width: '250px',
      data: { url: url },
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }
  editCampaign(name: string) {
    sessionStorage.setItem("campName", JSON.stringify(name))
    this.envAsyncService.setCurrentTab(1)
}
editTriggers(name:any){
  sessionStorage.setItem("campName", JSON.stringify(name))
  this.TriggerResponse.emit(name);
  this.envAsyncService.setCurrentTab(2)
}




}

