import { Component, OnInit } from '@angular/core';
import { EnvAsyncService } from '../util/env-async.service';
import { MigrationService } from './migration.service';

@Component({
  selector: 'app-migration',
  templateUrl: './migration.component.html',
  styleUrls: ['./migration.component.css']
})
export class MigrationComponent implements OnInit {

  selectedEnvironment: string = "";
  showSpinner: boolean = false;
  constructor(private migrationService: MigrationService, private envAsyncService: EnvAsyncService) { }

  ngOnInit(): void {
    this.showSpinner = true;
    this.migrationService.getCurrentEnv().subscribe((resp: any) =>{
      this.showSpinner = false;
      this.selectedEnvironment = resp.env;
      this.envAsyncService.setCurrentEnv(this.selectedEnvironment);
    }, err =>{
      this.showSpinner = false;
    })
  }
  selectEnv(){
    this.showSpinner = true;
    this.migrationService.selectEnv(this.selectedEnvironment).subscribe((resp: any)=>{
      this.showSpinner = false;
      this.envAsyncService.setCurrentEnv(resp.env);
      sessionStorage.setItem("ENV", resp.env)
    }, err =>{
      this.showSpinner = false;
    });
  }

  changeEnv(event: any){
    //this.envAsyncService.setCurrentEnv(event.target.value);
    this.selectedEnvironment = event.target.value
  }
}
