import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(private http: HttpClient) { }

  login(userName: string, password: string) {
    const basicAuthStr = 'Basic '+this.encodeString(userName+':'+password);
    console.log(basicAuthStr);
    const httpHeaders = new HttpHeaders().set('content-type', 'application/json')
      .set('Accept', 'application/json')
      .set('upauth', basicAuthStr)
      .set('Authorization', 'Basic SFNDQ05DYW1wYWlnbk1hbmFnZXJXZWJDbGllbnQ6U0ZORFEwNURRRzF3UURGbmJrMUFia0JuTTNKWE16aERiREV6Ym5RPQ==');
    return this.http.get(environment.loginUrl, { headers: httpHeaders });
  }

  encodeString(str: string){
    return btoa(str);
  }
}
